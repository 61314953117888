.stream{
    display: flex;
    justify-content: center;
}

/* Styles pour grand écrans */
.stream .video {
    width: 1100px;
    height: 619px;
}

.stream .chatembed {
    width: 320px;
    height: 1619px;
}

/* Styles pour < 1620px */
@media (max-width: 1620px) {
    .stream .video {
        width: 733px;
        height: 412px;
    }

    .stream .chat {
        width: 213px;
        height: 412px;
    }
}

@media (max-width: 672px)
{
    .stream .chat{
        display:none;
    }
    .stream .video {
        width: 100%;
        height: 100%;
    }
    .stream{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
    }
}
