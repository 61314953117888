.lettre-animation-container {
    width: 100%;
    height:100%;
    position: absolute;
    z-index: -5;
  }
  
  .lettre {
    position: absolute;
  }
  

